module.exports = {
  name: 'larkplayer-campaigns',
  cloundFront: {
    cdn_path: 'https://s.nxtpen.com', // CNAMES
    distribution_id: 'EVJR0ENDG1OP3',
  },
  sensor: {
    server_url: 'https://s.themsic.com/sa?project=larkplayer',
    // debug 地址
    debug_server_url:
      'https://debuger.larkplayerapp.com/debug?project=larkplayer',
  },
}
