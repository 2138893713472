// 不同环境加载不同配置
// sa 只能在 browser 运行

function throwError() {
  // throw new Error("can not call on server side");
}
const server = function () {
  return {
    track: throwError,
    quick: throwError,
    init: throwError,
  }
}

export default typeof window === 'undefined'
  ? server
  : require('./sa_report').default
