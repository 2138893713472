import 'core-js'

import React, { ReactElement, ReactNode } from 'react'
import { connect } from 'react-redux'
import { NextPage } from 'next'
import hybrid from '@mobiuspace/larkplayer-hybrid'
import App, { AppInitialProps, AppContext, AppProps } from 'next/app'
import I18nProvider from 'next-translate/I18nProvider'
import { getCookie, setCookie } from 'cookies-next'
import qs from 'qs'

import configs from '@/configs'
import i18n, { NEXT_LOCALE } from '@/i18n'
import { wrapper } from '@/store'
import {
  setAccountInfo,
  setIsHybrid,
  setLang,
  setVersionCode,
} from '@/store/global'
import sa from '@/utils/sa'
import '../styles/reset.css'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = { lang: string; dispatch: any } & AppProps & {
    Component: NextPageWithLayout
  }

type AppStates = {
  isInit: boolean
}

class MyApp extends App<AppPropsWithLayout, AppInitialProps, AppStates> {
  public static getInitialProps = wrapper.getInitialAppProps(
    (store) => async (appContext: AppContext) => {
      const { router, ctx } = appContext

      const { lang: queryLang } = router.query

      let lang = ''

      // 语言推导规则
      if (typeof window === 'undefined') {
        // server: query > browser > default
        // @ts-ignore
        const browserLang = ctx.req?.acceptsLanguages(...i18n.supportedLngs)
        if (queryLang) {
          if (i18n.supportedLngs.includes(queryLang as string)) {
            lang = queryLang as string
          } else {
            // 不在可支持的语言列表时，降级为默认语言
            lang = i18n.fallbackLng
          }
        } else if (browserLang) {
          lang = browserLang
        } else {
          lang = i18n.fallbackLng
        }
        setCookie(NEXT_LOCALE, lang, { req: ctx.req, res: ctx.res })
      } else {
        // client: cookies > default
        const cookiesLang = getCookie(NEXT_LOCALE) as string
        if (cookiesLang) {
          lang = cookiesLang
        } else {
          lang = i18n.fallbackLng
        }
      }
      store.dispatch(setLang(lang))

      return {
        pageProps: {
          // https://nextjs.org/docs/advanced-features/custom-app#caveats
          ...(await App.getInitialProps(appContext)).pageProps,
          // Some custom thing for all pages
          pathname: ctx.pathname,
        },
        lang,
      }
    }
  )

  async componentDidMount() {
    const { sensorDebug } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    sa.init({
      // 通过 sensorDebug=true 参数切换成调试模式
      server_url:
        sensorDebug === 'true'
          ? configs.sensor.debug_server_url
          : configs.sensor.server_url,
      is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
      use_client_time: true,
      send_type: 'beacon',
      show_log: true,
      heatmap: {
        //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
        clickmap: 'default',
        //是否开启触达注意力图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
        scroll_notice_map: 'default',
      },
    })

    try {
      this.init()
    } catch (error) {
      console.error('App---init error', error)
    }
  }

  init = async () => {
    const { dispatch } = this.props
    const isSupportHybrid = await hybrid.isSupportHybrid()
    dispatch(setIsHybrid(isSupportHybrid))

    if (isSupportHybrid) {
      await this.initInApp()
    }
  }

  initInApp = async () => {
    const { dispatch } = this.props
    try {
      const deviceId = await hybrid.getUDID()
      const region = await hybrid.getRegion()
      const versionCode = await hybrid.getThisVersionCode()
      dispatch(setAccountInfo({ deviceId, region }))
      dispatch(setVersionCode(versionCode))
      // 公共字段 device_id，random_id，region，distinct_id
      // sa.registerPage({
      //   device_id: deviceId,
      //   region,
      // })
    } catch (error) {
      console.error('App---initInApp error', error)
    }
  }

  // 动态注入语言包
  namespaces = () => {
    const { lang, pageProps } = this.props

    const obj: any = {
      common: require(`../i18n/common/${lang}.json`),
    }

    const ns = pageProps.ns

    if (ns) {
      obj[ns] = require(`../i18n/${ns}/${lang}.json`)
    }

    return obj
  }

  render() {
    const { Component, pageProps, lang } = this.props
    const namespaces = this.namespaces()

    // Use the layout defined at the page level, if available
    const getLayout = Component.getLayout ?? ((page) => page)

    return (
      <I18nProvider lang={lang} namespaces={namespaces}>
        {getLayout(<Component {...pageProps} />)}
      </I18nProvider>
    )
  }
}

export default wrapper.withRedux(connect()(MyApp))
