import { RootState } from '@/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

export interface User {
  /**
   * 设备 ID, 即 UDID
   */
  deviceId: string
  region: string
  musicPlayed: number
  listeningTime: number
  videoPlayed: number
  watchingTime: number
}

export interface GlobalState {
  lang: string
  isHybrid: boolean
  versionCode: number
  accountInfo: Partial<User>
}

const initialState: GlobalState = {
  lang: 'en',
  isHybrid: false,
  versionCode: 0,
  accountInfo: {},
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload
    },
    setIsHybrid: (state, action: PayloadAction<boolean>) => {
      state.isHybrid = action.payload
    },
    setVersionCode: (state, action: PayloadAction<number>) => {
      state.versionCode = action.payload
    },
    setAccountInfo: (state, action: PayloadAction<Partial<User>>) => {
      state.accountInfo = {
        ...state.accountInfo,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: any) => {
      return {
        ...state,
        ...action.payload.global,
      }
    })
  },
})

export const { setLang, setIsHybrid, setVersionCode, setAccountInfo } =
  globalSlice.actions

export const selectGlobal = (state: RootState) => state.global

export default globalSlice.reducer
